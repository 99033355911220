import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { token1, token2 } from "../components/SmallComponents/Images";

export default function Tokenomics() {
  const mobileMatches = useMediaQuery("(max-width:650px)");

  return (
    <Box pb={10}>
      <Container maxWidth="xl">
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Box component={"img"} alt="" src={token2} width={"100%"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box component={"img"} alt="" src={token1} width={"100%"} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
