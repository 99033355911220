import {
  Box,
  Container,
  Grid,
  useMediaQuery,
  Stack,
  InputAdornment,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import React, { useContext, useEffect, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import {
  StyledText,
  ToastNotify,
  StyledInput,
} from "../components/SmallComponents/AppComponents";
import Loading from "../components/SmallComponents/loading";
import { AppContext } from "../utils";
import { cro, logo } from "../components/SmallComponents/Images";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  tokenReadFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "viem";

export default function PresalePage() {
  const matches = useMediaQuery("(max-width:700px)");
  const { open } = useWeb3Modal();
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const { account, chainId } = useContext(AppContext);
  const [amountToBuy, setamountToBuy] = useState("");
  const [totalSoldTokens, settotalSoldTokens] = useState(0);
  const [callFunction, setCallFunction] = useState(true);
  const [tokenPerCRO, settokenPerCRO] = useState(0);
  const [tokenPerUSDT, settokenPerUSDT] = useState(0);
  const [userPurchasedTokens, setuserPurchasedTokens] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [isPresaleStart, setisPresaleStart] = useState(false);
  const [currentStage, setcurrentStage] = useState(0);
  const [isClaimEnabled, setisClaimEnabled] = useState(false);
  const [loading, setloading] = useState(false);
  const [receivedTokens, setreceivedTokens] = useState(0);
  const [totalRaised, settotalRaised] = useState(0);
  const [progressBar, setprogressBar] = useState(0);
  const [croBalance, setcroBalance] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const initVoidSigner = async () => {
    try {
      const ethToToken = await preSaleReadFunction("nativeToToken", [
        "1000000000000000000",
      ]);
      settokenPerCRO(+formatUnits(ethToToken?.toString(), 18));
      let sold = await preSaleReadFunction("soldToken");
      let price = await preSaleReadFunction("tokenPerCRO");
      settokenPrice(+formatUnits(price?.toString(), 18));
      let statusPresale = await preSaleReadFunction("isPresaleEnded");
      let start = await preSaleReadFunction("presaleStatus");
      setisClaimEnabled(statusPresale);
      setisPresaleStart(start);
      sold = +parseFloat(+formatUnits(sold?.toString(), 18)).toFixed(0);
      console.log(sold, "sold");
      settotalSoldTokens(toLocalFormat(sold));
      // const contractBal = await usdtReadFunction("balanceOf", [preSaleAddress]);
      const raisedUSDT = await preSaleReadFunction("amountRaised");
      let total = +parseFloat(+formatUnits(raisedUSDT?.toString(), 18)).toFixed(
        0
      );
      settotalRaised(toLocalFormat(total));
      let progress = (total / 2000000) * 100;
      setprogressBar(progress);
      setCallFunction(false);
    } catch (error) {
      setCallFunction(false);
      console.log(error, "ERROR VoidSigner Data");
    }
  };
  useEffect(() => {
    initVoidSigner();
  }, [callFunction]);

  const userTokenFunction = async () => {
    try {
      let dec = await tokenReadFunction("decimals");
      let userData = await preSaleReadFunction("users", [account]);
      let totalPurchasedUser = +formatUnits(
        userData[1]?.toString(),
        +dec?.toString()
      );
      setuserPurchasedTokens(parseFloat(totalPurchasedUser)?.toFixed(0));
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (account) {
      userTokenFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId]);
  const buyHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    if (!amountToBuy || amountToBuy <= 0) {
      return showAlert("Error! Please enter amount to buy.");
    }
    try {
      setloading(true);
      await preSaleWriteFunction(
        "buyToken",
        [],
        parseUnits(amountToBuy.toString(), 18).toString()
      );
      setamountToBuy("");
      setreceivedTokens(0);
      setCallFunction(true);
      setloading(false);
      showAlert("Success! Transaction Confirmed", "success");
    } catch (error) {
      setloading(false);
      console.log(error);
      showAlert(error?.shortMessage);
    }
  };
  const claimHandler = async () => {
    if (account) {
      try {
        setloading(true);
        await preSaleWriteFunction("claimTokens");
        setloading(false);
        setAlertState({
          open: true,
          message: `Transection Completed!`,
          severity: "success",
        });
      } catch (error) {
        setloading(false);
        setAlertState({
          open: true,
          message: error?.shortMessage,
          severity: "error",
        });
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };
  useEffect(() => {
    const calculatorUSDT = async () => {
      try {
        let tokenCRO = +tokenPerCRO * +amountToBuy;
        setreceivedTokens(tokenCRO?.toFixed(2));
      } catch (error) {}
    };
    if (+amountToBuy > 0) {
      calculatorUSDT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountToBuy]);

  const handleInputChange = (event) => {
    const input = event.target.value;
    const newValue = input?.replace(/[^0-9.]/g, "");
    setamountToBuy(newValue);
  };

  return (
    <Box pb={10}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Container maxWidth="xl">
        <Grid
          container
          spacing={matches ? 2 : 2}
          display="flex"
          justifyContent="center"
          flexWrap="wrap-reverse"
        >
          {/* <Grid item xs={12} md={7}>
            <Box mt={3} px={matches ? 0 : 10}>
              <StyledTitle>About Conglomerate</StyledTitle>
              <StyledText col="#000000">
                The Conglomerate Capital is a web3, BEP20 blockchain-based
                investment and funding platform, governed by a Decentralized
                Autonomous Organization (DAO), from which disruptive startups as
                well as SME businesses will raise capital, where investors will
                be able to access Venture Capital and Private Equity outstanding
                opportunities through the CONG token.
              </StyledText>
            </Box>
          </Grid> */}
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                boxShadow: "3px 3px 5px 1px #505050",
                bgcolor: "#A5DAB8",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  p: 3,
                }}
              >
                <Stack
                  flexDirection={{ xs: "column-reverse", sm: "row" }}
                  justifyContent={{ xs: "center", sm: "space-between" }}
                  alignItems={{ xs: "center", sm: "start" }}
                  gap={{ xs: 1, sm: 0 }}
                  my={1}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: { xs: "20px", sm: "26px" },
                      fontWeight: "600",
                      color: "#x`",
                    }}
                  >
                    GET IN EARLY!
                  </Typography>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    {isPresaleStart && (
                      <Box
                        sx={{
                          borderRadius: "50px",
                          backgroundColor: "#21C107",
                          py: 1,
                          px: 2,
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: { xs: "10px", sm: "12px" },
                            fontWeight: "600",
                            color: "#ffffff",
                          }}
                        >
                          Live
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Stack>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  my={1}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#ffffff",
                      fontFamily: "Outfit",
                      fontSize: { xs: "12px", sm: "15px" },
                      fontWeight: "600",
                      pr: 1.2,
                    }}
                  >
                    Min Contribution
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#6EAB5D",
                      fontFamily: "Outfit",
                      fontSize: { xs: "12px", sm: "17px" },
                      fontWeight: "800",
                      pr: 1.2,
                    }}
                  >
                    10 CRO
                  </Typography>
                </Stack>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  my={1}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#ffffff",
                      fontFamily: "Outfit",
                      fontSize: { xs: "12px", sm: "15px" },
                      fontWeight: "600",
                      pr: 1.2,
                    }}
                  >
                    Max Contribution
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#6EAB5D",
                      fontFamily: "Outfit",
                      fontSize: { xs: "12px", sm: "17px" },
                      fontWeight: "800",
                      pr: 1.2,
                    }}
                  >
                    5,888 CRO
                  </Typography>
                </Stack>

                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Box
                    sx={{
                      height: "2px",
                      // width: "50%",
                      background: "#6EAB5D",
                      flexGrow: 1,
                    }}
                  />
                  <Box
                    display={"flex"}
                    flexDirection={{ xs: "column", sm: "row" }}
                    gap={{ xs: 0.5, sm: 1, md: 0.8, xl: 1, lg: 1 }}
                    mx={{ xs: 1, md: 0.5, lg: 1, xl: 1 }}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: { xs: "10px", sm: "12px" },
                        textAlign: "center",
                        fontWeight: "700",
                        color: "#ffffff",
                        fontFamily: "Outfit",
                      }}
                    >
                      {console.log(tokenPrice, "tokenPrice")}1 SCOOTER ={" "}
                      {+tokenPrice > 0
                        ? parseFloat(1 / +tokenPrice).toFixed(7)
                        : "0.00"}{" "}
                      CRO
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      height: "2px",
                      flexGrow: 1,
                      background: "#6EAB5D",
                    }}
                  />
                </Stack>
                {/* <Box
            sx={{
              fontSize: { xs: "13px", sm: "15px" },
              textAlign: "center",
              fontWeight: "700",
              color: "#ffff",
              fontFamily: "Outfit",
              mt: 2,
            }}
          >
            Presale Starts In
          </Box>
          <Stack mt={1}>
            <TimerCountDown time={+stageEndTime} />
          </Stack> */}
                <Stack
                  my={1}
                  sx={{
                    mx: { xs: 0, sm: 1 },
                    px: 2,
                    py: 1,
                    borderRadius: "12px",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: { xs: 1, sm: 0 },
                    boxShadow: "3px 3px 5px 1px #929292",
                    background: "#9CD6A3",
                  }}
                >
                  <Stack flexGrow={1}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#ffff",
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "700",
                        textAlign: "left",
                      }}
                    >
                      TOKENS SOLD
                    </Typography>
                    <Stack
                      flexDirection={"row"}
                      alignItems="center"
                      my={1}
                      gap={0.5}
                    >
                      <Box
                        height={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Box
                          component={"img"}
                          alt=""
                          src={logo}
                          sx={{
                            width: "30px",
                            mt: { xs: 1, sm: 0 },
                            borderRadius: "50px",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#ffff",
                            fontFamily: "Outfit",
                            fontSize: { xs: "14px", sm: "17px" },
                            fontWeight: "700",
                            textAlign: "left",
                          }}
                        >
                          {totalSoldTokens}
                          <br />
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: { xs: "10px", sm: "9px" },
                            }}
                          >
                            / 280,000,000,000
                          </span>
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                  {mobileMatches ? (
                    <Divider
                      sx={{
                        background: "#353535",
                      }}
                    />
                  ) : (
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{
                        background: "#353535",
                        mr: 1,
                      }}
                    />
                  )}

                  <Stack flexGrow={1}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#ffff",
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "700",
                        textAlign: "left",
                      }}
                    >
                      CRO RAISED
                    </Typography>
                    <Stack
                      flexDirection={"row"}
                      alignItems="center"
                      mt={1}
                      gap={0.5}
                    >
                      <Box
                        height={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Box
                          component={"img"}
                          alt=""
                          src={cro}
                          sx={{ width: "30px", mt: { xs: 1, sm: 0 } }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#ffff",
                            fontFamily: "Outfit",
                            fontSize: { xs: "14px", sm: "17px" },
                            fontWeight: "700",
                            textAlign: "left",
                          }}
                        >
                          {totalRaised}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Box
                    sx={{
                      height: "2px",
                      // width: "50%",
                      background: "#6EAB5D",
                      flexGrow: 1,
                    }}
                  />

                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: { xs: "10px", sm: "13px" },
                      textAlign: "center",
                      fontWeight: "700",
                      color: "#ffffff",
                      fontFamily: "Outfit",
                      mx: 2,
                      // width: "120px",
                    }}
                  >
                    {account ? (
                      <StyledText>Balance: {croBalance} CRO</StyledText>
                    ) : (
                      "CONNECT WALLET"
                    )}
                  </Typography>

                  <Box
                    sx={{
                      height: "2px",
                      flexGrow: 1,
                      // width: "50%",
                      background: "#6EAB5D",
                    }}
                  />
                </Stack>
                <Stack
                  sx={{
                    flexDirection: { xs: "column", sm: "row" },
                    gap: { xs: 1, sm: 2 },
                    my: 2,
                  }}
                >
                  <Box>
                    <StyledInput
                      type="text"
                      placeholder="Enter Amount"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Box
                              component={"img"}
                              alt=""
                              src={cro}
                              style={{
                                width: "30px",
                                marginLeft: "-10px",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      value={amountToBuy}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box>
                    <StyledInput
                      type="number"
                      placeholder="0"
                      value={amountToBuy > 0 ? receivedTokens : "0"}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="end">
                            <Box
                              component={"img"}
                              alt=""
                              src={logo}
                              style={{
                                width: "27px",
                                marginLeft: "-10px",
                                borderRadius: "50px",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack my={1} gap={2}>
                  {!isClaimEnabled && (
                    <Button
                      onClick={async () =>
                        account ? buyHandler() : await open()
                      }
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "600",
                        background: "#6EAB5D",
                        color: "#ffffff",
                        borderRadius: "12px",
                        height: "50px",
                        "&:hover": { background: "#6EAB5D" },
                      }}
                    >
                      {account ? "BUY" : "CONNECT"}
                    </Button>
                  )}

                  {isClaimEnabled && account ? (
                    <Button
                      onClick={async () => claimHandler()}
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "600",
                        background: "#6EAB5D",
                        color: "#ffffff",
                        borderRadius: "12px",
                        height: "50px",
                        "&:hover": { background: "#6EAB5D" },
                      }}
                    >
                      Claim
                    </Button>
                  ) : null}
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
